import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import * as Cookies from 'js-cookie'


import user from './user'
import data from './data'

Vue.use(Vuex)

const EXPIRED_IN_DAYS = 1

export default new Vuex.Store({
  modules: {user, data},
  plugins: [createPersistedState({
      paths: ['user', 'data'],
      getItem: key => Cookies.get(key),
      setItem: (key, value) => Cookies.set(key, value, { expires: EXPIRED_IN_DAYS, secure: true }),
  })]
})
