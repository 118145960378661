<template>
  <div style="width: 100%; padding: 0 10px 10px 10px">
    <div style="text-align: center;">
      <h4 class="hide-on-small">Автоматизированная Информационная Система Предупреждения о Цунами (АИСПЦ) </h4>
    </div>
    <md-dialog :md-active="getInfoAlertVisibility" >
      <md-dialog-content style="padding: 20px; min-width: 260px">
        {{getInfoAlertText}}
      </md-dialog-content>
      <md-dialog-actions>
        <md-button class="md-raised md-primary"  @click="SET_INFO_ALERT_VISIBILITY()">Закрыть</md-button>
      </md-dialog-actions>
    </md-dialog>
    <div class="top-wide">
      <div style="display: flex; justify-content: space-evenly;  gap: 20px; align-items: center;">
        <span class="time">Москва {{moscowTime}}</span>
        <span class="time">Всемирное согласованное время (ВСВ) {{universalTime}}</span>
        <span class="time">Южно-Сахалинск {{sakhalinTime}}</span>
        <span class="time">Владивосток {{vladivostokTime}}</span>
        <span class="time">Петропавловск-Камчатский {{kamchatkaTime}}</span>
      </div>
      <div v-if="logoutAvailable">
        <div style="display: flex; justify-content: flex-end;  gap: 20px; align-items: baseline; margin-top: 20px; flex-wrap: wrap">
          <span class="menu" v-if="isAdmin" @click="viewUsers" >
            Пользователи
          </span>
          <span class="menu" v-if="isAdmin"  @click="viewLogs" >
            Логи
          </span>
          <span class="menu" v-show="isExpert" @click="viewExpert" >
            АРМ Эксперта
          </span>
          <span class="menu" v-show="isExpert" >
            Архив сообщений
          </span>
          <span class="menu" v-show="isExpert" >
            Архив документов
          </span>
          <span class="menu" v-show="isOceanographer" @click="viewOceanographer" >
            АРМ Океанолога
          </span>
          <span class="menu" v-show="isSeismologist" @click="viewSeismologist" >
            АРМ Сейсмолога
          </span>
          <span class="menu" v-show="isOceanographer" @click="viewSea" >
            Датчик уровня моря
          </span>
          <span class="menu" v-show="isOceanographer || isSeismologist" @click="toggleSound" >
            {{getSoundTitle}}
          </span>
          <span class="time exit" style="cursor: pointer; " @click="goLogout">
            <input type="button" value="Выход" style="width: 60px"/>
          </span>
        </div>
      </div>
    </div>
    <div class="top-narrow">
      <div style="display: flex; justify-content: space-evenly;  gap: 20px; align-items: flex-end;">
        <span class="time">МСК {{moscowTime}}</span>
        <span class="time">ВСВ {{universalTime}}</span>
        <span class="time">Ю-Сах {{sakhalinTime}}</span>
        <span class="time">Влад {{vladivostokTime}}</span>
        <span class="time">П-Камч {{kamchatkaTime}}</span>
      </div>
      <div v-show="logoutAvailable" :key="'logout_' + isAdmin">
        <div style="display: flex; justify-content: flex-end;  gap: 20px; align-items: flex-end; margin-top: 20px; flex-wrap: wrap">
          <span class="menu" v-show="isAdmin"  @click="viewUsers" style="text-decoration: underline; cursor: pointer">
            Пользователи
          </span>
          <span class="menu" v-show="isAdmin"  @click="viewLogs" style="text-decoration: underline; cursor: pointer">
            Логи
          </span>
          <span class="menu" v-show="isExpert" @click="viewExpert" style="text-decoration: underline; cursor: pointer">
            АРМ Эксперта
          </span>
          <span class="menu" v-show="isExpert" style="text-decoration: underline; cursor: pointer">
            Архив сообщений
          </span>
          <span class="menu" v-show="isExpert" style="text-decoration: underline; cursor: pointer">
            Архив документов"
          </span>
          <span class="menu" v-show="isOceanographer " @click="viewOceanographer" style="text-decoration: underline; cursor: pointer">
            АРМ Океанолога
          </span>
          <span class="menu" v-show="isSeismologist" @click="viewSeismologist" style="text-decoration: underline; cursor: pointer">
            АРМ Сейсмолога
          </span>
          <span class="menu"  v-show="isOceanographer " @click="viewSea" style="text-decoration: underline; cursor: pointer">
            Датчик уровня моря
          </span>
          <span class="menu" v-show="isOceanographer || isSeismologist" @click="toggleSound" style="text-decoration: underline; cursor: pointer">
            {{getSoundTitle}}
          </span>
          <span class="time exit" style="cursor: pointer; " @click="goLogout">
            <input type="button" value="Выход" style="width: 60px"/>
          </span>
        </div>
      </div>
    </div>



  </div>
</template>

<script>
  import {getItem} from "../utils/persistanceStorage";
  import logger from "../utils/logger";
  import {getFormattedTime} from "../utils/timezone";
  import {logout} from "../api/auth-controller";
  import eventBus from './eventBus.js'

  import {mapActions, mapGetters, mapMutations} from 'vuex'

  let startDate = new Date()

  export default {
    name: 'TopHeader',
    data() {
      return {
        isLoading: true,
        interval: null,
        moscowTime: getFormattedTime('Europe/Moscow', startDate),
        universalTime: getFormattedTime('UTC', startDate),
        vladivostokTime: getFormattedTime('Asia/Vladivostok', startDate),
        sakhalinTime: getFormattedTime('Asia/Sakhalin', startDate),
        kamchatkaTime: getFormattedTime('Asia/Kamchatka', startDate),
        loginCheckDate: null
      }
    },
    computed: {
      ...mapGetters([
        'getSoundTitle',
        'getSoundOffStatus',
        'getInfoAlertText',
        'getInfoAlertVisibility',
        'getRefreshTokenInterval'
      ]),
      logoutAvailable() {
        //TODO fix issue on first load
        // console.log('isLogoutAvailable', this.$route.meta.auth)
        return this.$route.meta && this.$route.meta.auth === true && !!getItem('auth') && !this.isLoading
      },
      isAdmin() {
        if (this.loginCheckDate) {
          let isAdmin = !!getItem('auth') && !!getItem('auth').roles && getItem('auth').roles.includes("administrator")
          console.log('isAdmin', isAdmin)
          return isAdmin
        } else {
          return !!getItem('auth') && !!getItem('auth').roles && getItem('auth').roles.includes("administrator")
        }
      },
      isOceanographer() {
        if (this.loginCheckDate) {
          let isOceanographer = !!getItem('auth') && !!getItem('auth').roles && getItem('auth').roles.includes("oceanographer")
          console.log('isOceanographer', isOceanographer)
          return isOceanographer
        } else {
          return !!getItem('auth') && !!getItem('auth').roles && getItem('auth').roles.includes("oceanographer")
        }
      },
      isSeismologist() {
        if (this.loginCheckDate) {
          let isSeismologist = !!getItem('auth') && !!getItem('auth').roles && getItem('auth').roles.includes("seismologist")
          console.log('isSeismologist', isSeismologist)
          return isSeismologist
        } else {
          return !!getItem('auth') && !!getItem('auth').roles && getItem('auth').roles.includes("seismologist")
        }
      },
      isExpert() {
        if (this.loginCheckDate) {
          let isExpert = !!getItem('auth') && !!getItem('auth').roles && getItem('auth').roles.includes("expert")
          console.log('isExpert', isExpert)
          return isExpert
        } else {
          return !!getItem('auth') && !!getItem('auth').roles && getItem('auth').roles.includes("expert")
        }
      },
    },
    methods: {
      ...mapMutations([
        'SET_SOUND_ON',
        'SET_SOUND_OFF',
        'SET_INFO_ALERT_VISIBILITY',
      ]),
      ...mapActions([
        'stateLogout',
        'handleRefreshToken'
      ]),
      toggleSound() {
        console.log('sound status', this.getSoundOffStatus)
        return (this.getSoundOffStatus ?  this.SET_SOUND_ON() : this.SET_SOUND_OFF() )
      },
      async goLogout() {
        logger('goLogout')
        try {
          await logout()
        } finally {
          this.stateLogout()
          this.$router.push('/signin')
        }
      },
      visibleName() {
        return `${getItem('auth').name} ${getItem('auth').patronymic}`
      },
      viewSea() {
        this.$router.push({path: '/sea-data'});
      },
      viewUsers() {
        this.$router.push({path: '/users'});
      },
      viewLogs() {
        this.$router.push({path: '/logs'});
      },
      // viewSignals() {
      //   this.$router.push({path: '/signals'});
      // },
      viewOceanographer() {
        this.$router.push({path: '/oceanographer'});
      },
      viewSeismologist() {
        this.$router.push({path: '/seismologist'});
      },
      viewExpert() {
        this.$router.push({path: '/expert'});
      }
    },
    mounted() {
      let that = this
      this.interval = window.setInterval(() => {
        if (!that) return
        let date = new Date()
        that.moscowTime = getFormattedTime('Europe/Moscow', date)
        that.universalTime = getFormattedTime('UTC', date)
        that.vladivostokTime = getFormattedTime('Asia/Vladivostok', date)
        that.sakhalinTime = getFormattedTime('Asia/Sakhalin', date)
        that.kamchatkaTime = getFormattedTime('Asia/Kamchatka', date)
      }, 1000)
      this.isLoading = false
      eventBus.$on('login-event', () => {
        this.loginCheckDate = new Date
      })
      if (getItem('auth') != null) {
        this.handleRefreshToken()
      }
    },
    destroyed() {
      if (this.interval) {
        try {
          window.clearInterval(this.interval)
        } catch (e) {
          //ignore
        }
      }
      eventBus.$off('login-event')
    }
  }
</script>

<style >
  .exit:hover {
    text-decoration: underline;
  }
  @media (min-width: 801px) {
    .top-wide {
      display: block;
    }
    .top-narrow {
      display: none;
    }
  }

  @media (max-width: 800px) {
    .top-wide {
      display: none;
    }
    .top-narrow {
      display: block;
    }

    .md-table-content {
      max-height: unset !important;
    }
  }

  span.time {
    /*border: 1px solid red;*/
    text-align: center;
    padding: auto
  }

  span.time+span.time {
    /*border-left: 1px solid red*/

  }
</style>

<style scoped>
  span.menu {
    border: 2px solid gray;
    /*padding: 0 5px;*/
    cursor: pointer;
    height: 20px;
    font-size: 14px;
    padding: 1px 6px;
    box-sizing: content-box;
  }
</style>
