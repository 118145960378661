export function getDate(dateTime) {
  if (!dateTime) return ''
  // console.log('date0', dateTime)
  let date
  if (dateTime instanceof Date) {
    date = dateTime
  } else {
    date = new Date(dateTime)
  }
  // console.log('date1', date)
  return Intl.DateTimeFormat([],{
    day: "numeric",
    month: "numeric",
    year: "numeric",
    timeZone: 'UTC',
  }).format(date)
}

export function reformatDateInput(stringDate, intl) {
  let year, month, day
  if (stringDate.indexOf('-') === 4) { // YYYY-mm-dd
    year = stringDate.substring(0, 4)
    month = stringDate.substring(5, 7)
    day = stringDate.substring(8, 10)
  } else {                          //dd.mm.yyyy
    day = stringDate.substring(0, 2)
    month = stringDate.substring(3, 5)
    year = stringDate.substring(6, 10)
  }

  let result
  if (intl) {
    result = `${day}.${month}.${year}`
  } else {
    result = `${year}-${month}-${day}`
  }
  // console.log(`getUtcDateFromInput ${intl ? 'intl' : ''}`, stringDate, result)
  return result
}

export function getTime(dateTime) {
  if (!dateTime) return ''
  let date
  if (dateTime instanceof Date) {
    date = dateTime
  } else {
    date = new Date(dateTime)
  }
  // console.log('date2', date)
  return Intl.DateTimeFormat([],{
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    timeZone: 'UTC',
  }).format(date)
}

export function getHoursAndMinutes(dateTime) {
  if (!dateTime) return ''
  let date
  if (dateTime instanceof Date) {
    date = dateTime
  } else {
    date = new Date(dateTime)
  }
  // console.log('date2', date)
  return Intl.DateTimeFormat([],{
    hour: "numeric",
    minute: "numeric",
    timeZone: 'UTC',
  }).format(date)
}

export function getDateTime(dateTime, noSecondsFlag) {
  if (!dateTime) return ''
  let date
  if (dateTime instanceof Date) {
    date = dateTime
  } else {
    date = new Date(dateTime)
  }
  // console.log('date2', date)
  let params = {
    day: "numeric",
    month: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    timeZone: 'UTC'}

    if (!noSecondsFlag) {
      params.second = "numeric"
    }

  return Intl.DateTimeFormat([],params).format(date)
}

export function timeFromSeconds(seconds) {
  return new Date(seconds * 1000)
}

export function compileDate(dateString, timeString) {
  let result = null
  try {
    result = `${dateString}T${timeString}Z`
  } catch (e) {
    console.error('compileDate', e)
  } {
    return result
  }
}

export function getUtcDateFromInput(stringDate, intl) {
  let year, month, day, hour, minute;
  if (stringDate.indexOf('-') === 4) { // YYYY-mm-ddTHH:MM
    year = stringDate.substring(0, 4)
    month = stringDate.substring(5, 7)
    day = stringDate.substring(8, 10)
    hour = stringDate.substring(11, 13)
    minute = stringDate.substring(14, 16)
  } else {                          //dd.mm.yyyy HH:MM
    day = stringDate.substring(0, 2)
    month = stringDate.substring(3, 5)
    year = stringDate.substring(6, 10)
    hour = stringDate.substring(11, 13)
    minute = stringDate.substring(14, 16)
  }

  let result
  if (intl) {
    result = `${day}.${month}.${year}, ${hour}:${minute}`
  } else {
    result = `${year}-${month}-${day}T${hour}:${minute}`
  }
  console.log(`getUtcDateFromInput ${intl ? 'intl' : ''}`, stringDate, result)
  return result
}

export function dateInputToDateString(stringDate) {
  let year, month, day, hour, minute;
  if (stringDate.indexOf('-') === 4) { // YYYY-mm-ddTHH:MM
    year = stringDate.substring(0, 4)
    month = stringDate.substring(5, 7)
    day = stringDate.substring(8, 10)
    hour = stringDate.substring(11, 13)
    minute = stringDate.substring(14, 16)
  }

  let result
  // "2024-04-01T02:00:46.167+03:00"
  result = `${year}-${month}-${day}T${hour}:${minute}:00Z`
  console.log(`dateInputToDateString `, stringDate, result)
  return result
}


export function getUTCDateTitleWithOffsetFromDate(date, offset) {
  if (!date) return ''
  let rawDate;
  if (date instanceof Date) {
    rawDate = date
  } else {
    rawDate = new Date(date)
  }
  // let translate = getDateTime(date, true)
  rawDate.setSeconds(rawDate.getSeconds() + +offset)
  return getDateTime(rawDate, false) + ' ВСВ'// + ' (' + translate + ' + '+ offset + ')'
}

export function getUTCDateWithOffsetFromDate(date, offset) {
  if (!date) return ''
  let rawDate;
  if (date instanceof Date) {
    rawDate = date
  } else {
    rawDate = new Date(date)
  }
  // let translate = getDateTime(date, true)
  rawDate.setSeconds(rawDate.getSeconds() + +offset)
  return rawDate//getDateTime(rawDate, false) + ' ВСВ'// + ' (' + translate + ' + '+ offset + ')'
}

export const HOURS_24_MS = 24 * 60 * 60 * 1000
