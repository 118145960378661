import AudioLoader from './audioLoader'

export class SoundCall {
  play (type, volume) {
    console.log('play', type)
    if (!volume) {
      volume = 1
    }
    switch (type) {
      case 'signal':
        return this.signal(volume)
      case 'message':
        return this.message(volume)
      default:
        // return this.general(volume)
    }
  }

  constructor () {
    this.audioLoader = new AudioLoader()
    this.alertInterval;
    this.playSound = (sound, volume) => {
      sound.volume = volume ? volume : 1
      return sound.play()
    }
    this.signal = (volume) => {
      return this.playSound(this.audioLoader.signal(), volume)
    }
    this.message = (volume) => {
      return this.playSound(this.audioLoader.message(), volume)
    }

    this.checkAndPlaySignals = (signals) => {
      clearTimeout(this.alertInterval)
      signals.forEach(s => {
        console.log('signal ', s.id, s.earthquakeDate, (new Date() - new Date(s.earthquakeDate) < (1000 * 3600 * 24 * 3)))
      })
      if (!signals || signals.length === 0) {
        return;
      }
      if (signals.filter(s => (new Date() - new Date(s.earthquakeDate)) < (1000 * 3600 * 24 * 3)).length) {
        // console.log('some events', signals.filter(s => (new Date() - new Date(s.earthquakeDate)) < (1000 * 3600 * 24 * 3)))
        let audio = new Audio('../audio/alert.wav')
        audio.muted = false
        audio.autoplay = true
        audio.volume = 0.2
        audio.play()
        this.alertInterval = setTimeout(() => audio.play(), 3000)
        // this.signal()
      }
      // else {
      //   console.log('else')
      // }
    }

    this.checkAndPlayMessage = (messages) => {
      // if (this.alertInterval) return;
      clearTimeout(this.mailInterval)

      if (!messages || messages.length === 0) {
        return;
      }
      if (messages.filter(s => (new Date() - new Date(s.received)) < (1000 * 3600 * 24 * 3)).length) {
        // console.log('some events', messages.filter(s => (new Date() - new Date(s.received)) < (1000 * 3600 * 24 * 3)))
        let audio = new Audio('../audio/mail.wav')
        audio.muted = false
        audio.autoplay = true
        audio.volume = 0.2
        audio.play()
        this.mailInterval = setTimeout(() => audio.play(), 3000)
        // this.signal()
      }
      // else {
      //   console.log('else')
      // }
    }


  }
}
