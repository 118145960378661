// user-controller
import axiosClient from "./axiosWrapper";
import logger from "../utils/logger";

export const getUsers = async () => { //no params
  let result = await axiosClient().get('/users')

  return result
}

export const getUser = async (userId) => {
  logger('getUser', userId)
  let result = await axiosClient().get(`/users/${userId}`)
  logger('',result)
  return result
}

export const refreshTokenOn = async () => {
  console.log('try to refhresh token at', (new Date()).toUTCString())
  return await axiosClient().refreshToken()
}


