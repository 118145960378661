import {getResults} from "../api/result-controller";
import {getGauges} from "../api/gauge-controller";
import {getLocations} from "../api/location-controller";

const defaultState = () => ({
  calcResults: [],
  gauges: [],
  points: []
})

const state = defaultState();

const mutations = {
  SET_CALC_RESULTS(state, value) {
    state.calcResults = value
  },
  SET_GAUGES(state, value) {
    state.gauges = value
  },
  SET_BASE_POINTS(state, value) {
    state.points = value
  }
}

const getters = {
  getCalcResults(state) {
    return state.calcResults
  },
  getGauges(state) {
    return state.gauges
  },
  getBasePoints(state) {
    return state.points
  }
}

const actions = {
  async loadCalcResults({commit}, signalId) {
    let calcResultsResponse = await getResults(signalId)
    if (calcResultsResponse.success) {
      commit('SET_CALC_RESULTS', calcResultsResponse.payload)
    } else {
      commit('SET_CALC_RESULTS', [])
    }
  },
  async loadGauges({commit}) {
    let gaugesResp = await getGauges()
    if (gaugesResp && gaugesResp.success) {
      commit('SET_GAUGES', gaugesResp.payload)
    } else {
      commit('SET_GAUGES', [])
    }
  },
  async loadBasePoints({commit}) {
    let pointsResp = await getLocations()
    if (pointsResp && pointsResp.success) {
      commit('SET_BASE_POINTS', pointsResp.payload)
    } else {
      commit('SET_BASE_POINTS', [])
    }
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}


